<template>
  <div class="content-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-block card-stretch card-height blog blog-detail">
            <div class="card-header d-flex justify-content-center">
              <div class="header-title ">
                <h5 class="card-title text-success">Consent for personal data processing</h5>
              </div>
            </div>
            <div class="card-body">
              <div class="blog-description mt-3">
                <!-- <h5 class="mb-3 pb-3 border-bottom">In simple words:</h5> -->
                <h5 class="mb-1 pb-1 fw-bold">In simple words:</h5>
                <ul>
                  <li>
                    In order to use the services you need to perform an identity verification process, as part of
                    this
                    process biometric data of your face and personal data from your government identity document
                    document
                    will be captured through camera and processed to authenticate.
                  </li>
                  <li>
                    UAB “Ondato” acts as a data processor and stores the personal data for no longer than it is obliged
                    by the company who’s services you want to use.
                  </li>
                  <li>
                    If you have any questions about how Ondato or OnlyFans please contact <a
                      href="mailto:support@onlyfans.com">support@example.com </a> or check
                    OnlyFans Privacy Policy.

                  </li>
                </ul>
                <h5 class="mb-2 pb-2 fw-bold">Legal version:</h5>
                <h6 class="mb-0 pb-0 fw-bold">Required data</h6>
                <p>Please note we use Ondato’s facial recognition identity verification services to authenticate you and
                  your government identity document. In order to obtain the service from OnlyFans, by clicking “[I
                  agree]” I agree that Ondato will receive and manage my personal data, including my biometric data,
                  gained from remote identity verification of my personal identity by taking and/or filming live image
                  of my face, my person’s identity or the document used to identify it and/or their image and the
                  personal data contained in them and/or check in the population registry: image of my face, name,
                  surname, nationality, gender, personal code, date of birth, numbers of the document that is being
                  used, date of issue and validity, my signature and the transfer of such data to OnlyFans.</p>
                <h6 class="mb-0 pb-0 fw-bold">Data processing</h6>
                <p>I am aware that Ondato is acting as a data processor on behalf of OnlyFans and my personal data,
                  including my biometric data, will be processed by Ondato until the remote identity verification
                  procedure is complete and the results will be transferred to OnlyFans. OnlyFans does not receive any
                  facial biometric information generated from the images, and our third-party service providers are
                  contractually limited to processing this information on our behalf and are required to destroy the
                  images and any biometric information promptly in accordance with a data retention schedule and
                  OnlyFans instructions as set forth in the “Retention of Personal Data” section above.

                </p>
                <h6 class="mb-0 pb-0 fw-bold">Data handling
                </h6>
                <p>I am aware that I can submit request for information regarding my personal data by contacting at
                  support@onlyfans.com.
                </p>
                <p>I declare and confirm, that all the personal data I will provide for remote identity verification, is
                  correct and accurate, and that any government documents which I will use for this purpose is
                  authentic, valid and is provided to authenticate my identity.
                </p>
              </div>
              <div class="d-flex justify-content-center">
                <router-link to="/" class="shadow btn btn-outline-primary rounded-pill mb-3 me-1">
                  {{$t('link.disagree')}}</router-link>
                <router-link to="/auth/VerifyPhone" class="shadow btn btn-primary rounded-pill mb-3 me-1">
                  {{$t('link.Iagree')}}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OmniChannel'
}
</script>

